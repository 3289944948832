<template>
  <base-material-card>
    <template v-slot:heading>
      <div class="text-h3 font-weight-light">
        {{ $t('profile.changePass') }}
      </div>

      <!--              <div class="text-subtitle-1 font-weight-light">-->
      <!--                Complete your profile-->
      <!--              </div>-->
    </template>

    <v-form id="passForm" @submit.prevent="changePass">
      <v-container class="py-0">
        <v-row>
          <v-col cols="12" :class="{ 'has-error': validation.hasError('oldPass') }">
            <v-text-field
              :label="$t('profile.oldPass')"
              class="purple-input"
              v-model="oldPass"
              hide-details
              :type="showOldPass ? 'text' : 'password'"
              :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showOldPass = !showOldPass)"
            />
            <span v-if="validation.hasError('oldPass')" class="val-error">{{
              validation.firstError('oldPass')
            }}</span>
          </v-col>

          <v-col cols="12" :class="{ 'has-error': validation.hasError('newPass') }">
            <v-text-field
              :label="$t('profile.newPass')"
              class="purple-input"
              v-model="newPass"
              hide-details
              :type="showNewPass ? 'text' : 'password'"
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showNewPass = !showNewPass)"
            />
            <span v-if="validation.hasError('newPass')" class="val-error">{{
              validation.firstError('newPass')
            }}</span>
          </v-col>

          <v-col cols="12" :class="{ 'has-error': validation.hasError('confirmPass') }">
            <v-text-field
              :label="$t('profile.confirmPass')"
              class="purple-input"
              v-model="confirmPass"
              hide-details
              :type="showConfirmPass ? 'text' : 'password'"
              :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showConfirmPass = !showConfirmPass)"
            />
            <span v-if="validation.hasError('confirmPass')" class="val-error">{{
              validation.firstError('confirmPass')
            }}</span>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn color="success" class="mr-0" @click="changePass">
              {{ $t('profile.changePass') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-material-card>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
export default {
  mixins: [HelperMixin],
  props: ['user'],
  data: () => ({
    oldPass: null,
    newPass: null,
    confirmPass: null,
    showOldPass: false,
    showNewPass: false,
    showConfirmPass: false,
  }),
  validators: {
    oldPass(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.profile.oldPass.required'))
        .minLength(6, this.$i18n.t('errors.profile.oldPass.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.profile.oldPass.max', { maxChar: 255 }));
    },
    newPass(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.profile.newPass.required'))
        .minLength(6, this.$i18n.t('errors.profile.newPass.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.profile.newPass.max', { maxChar: 255 }));
    },
    'confirmPass, newPass': function (confirmPass, newPass) {
      return Validator.value(confirmPass)
        .required(this.$i18n.t('errors.profile.confirmPass.required'))
        .match(newPass, this.$i18n.t('errors.profile.confirmPass.notMatched'));
    },
  },
  methods: {
    async changePass() {
      const isValid = await this.$validate();
      if (isValid) {
        console.log('CHANGE PASS!');
        let requestData = {
          old: this.oldPass,
          password: this.newPass,
        };
        const result = await this.$store.dispatch('profile/changePassword', requestData);
        console.log('result chage password', result);
        if (result.error) {
          this.$swal('Oops', result.error, 'error');
        } else {
          this.$swal('Success', this.$t('profile.changePassSuccess'), 'success');

          this.oldPass = null;
          this.newPass = null;
          this.confirmPass = null;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
